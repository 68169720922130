import { useContext, useEffect, useState } from "react";

import { useCreateCart, useUpdateCart } from "../../../hooks";
import _ from "lodash";
import { CartContext } from "../../../context";
import useSyncCartWhenMethodChange from "./updateCart/methodChange";

const useSyncCart = () => {
  const cartContext = useContext(CartContext);

  const [cartInSync, setCartInSync] = useState(false);

  const { created: cartCreated, onCreate: onCreateCart } = useCreateCart();
  const { updated: updateDone, onUpdate: updateCart } = useUpdateCart();

  const [orderTypeChange, setOrderTypeChange] = useState(null);
  useSyncCartWhenMethodChange({ setOrderTypeChange });

  useEffect(() => {
    if (!_.isEmpty(cartContext.cartToken)) return;
    setCartInSync(true);
    onCreateCart();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartContext.cartToken]);

  // update cart
  useEffect(() => {
    if (cartInSync) return;
    if (_.isEmpty(cartContext.cartToken)) return;
    if (orderTypeChange === false) return;

    updateCart("CHECKOUT");
    setOrderTypeChange(false);
    setCartInSync(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartContext.cartToken, orderTypeChange]);

  useEffect(() => {
    if (updateDone === false) return;
    setCartInSync(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDone]);

  useEffect(() => {
    if (cartCreated === false) return;
    setCartInSync(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartCreated]);

  return { cartInSync };
};

export default useSyncCart;
