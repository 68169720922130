import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { CartContext, ModalContext } from "../../context";
import _ from "lodash";
import { LocationsContext } from "../../context";
import { useMixpanel } from "gatsby-plugin-mixpanel";
import { useUpdateLocationBasedOnPostalCode } from "../../hooks";
import { useTrans } from "../../hooks";

const OrderType = ({ label, hasIcon, showModal }) => {
  const transMsg = useTrans();
  const locationsContext = useContext(LocationsContext);
  const cartContext = useContext(CartContext);
  const { setShowDeliveryModal } = useContext(ModalContext);

  // initialize mixpanel
  const mixpanel = useMixpanel();
  // update the selected location based on the postal code and shipping address from the cart context
  const updateSelectedLocation = useUpdateLocationBasedOnPostalCode();

  // =========================================================
  return (
    <>
      <div className="row">
        <div className="col-12 ">
          {hasIcon && (
            <>
              <label
                className="my-1 mr-sm-2 mb-2 h6-text"
                htmlFor="inlineFormInputSelectTime"
              >
                <i
                  className="icofont-map-pins   icofont-1x mr-1"
                  style={{ color: "rgb(41, 41, 41)" }}
                ></i>
                {!_.isEmpty(label) && <>{label}:</>}
              </label>
              <br />
            </>
          )}

          <Button
            onClick={() => {
              // if the location has an external delivery link, we will open the external delivery modal
              if (!_.isEmpty(locationsContext.selectedLocation?.externalDeliverylink)) {
                locationsContext.setIsOpenExternalDeliveryModal(true);
                return;
              }

              // if he has selected the order type delivery
              // run this function to update the selected location based on the postal code
              // this is only when the restaurant has delivery options enabled meaning
              // he has his own delivery service
              updateSelectedLocation();

              // set the order type to delivery
              cartContext.setOrderType("delivery");

              // track the event in mixpanel
              if (process.env.MIXPANEL_API_TOKEN) mixpanel.track("deliverySelected");

              // if the restaurant does not have delivery options enabled
              // we will open the delivery modal
              // to get the delivery address from the customer
              // and then we will calculate the delivery fee
              // using the delivery service provider (eg. Uber eats, DoorDash, etc.)
              if (showModal) setShowDeliveryModal(true);
            }}
            disabled={!locationsContext.selectedLocation?.delivery?.enable}
            // add the active-btn class if the order type is delivery for styling and
            // add the ml-2OFF class to add some margin to the left
            className={
              cartContext.orderType === "delivery"
                ? "delivery-btn ml-2OFF active-btn"
                : "delivery-btn ml-2OFF"
            }
          >
            {transMsg("delivery")}
          </Button>
          <Button
            onClick={() => {
              // if the user has selected the order type pickup
              // we will set the order type to pickup in the cart context
              cartContext.setOrderType("pickup");

              // let's check if there are multiple locations
              // if there are multiple locations, we will open the multi location modal
              // to let the user select the location he wants to pickup from
              if (locationsContext.locations.length > 1)
                locationsContext.setIsOpenMultiLocationModal(true);

              // track the event in mixpanel
              if (process.env.MIXPANEL_API_TOKEN) mixpanel.track("pickupSelected");
            }}
            disabled={!locationsContext.selectedLocation?.pickup?.enable}
            className={
              cartContext.orderType === "pickup"
                ? "delivery-btn2 active-btn"
                : "delivery-btn2"
            }
          >
            {transMsg("pickup")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default OrderType;
