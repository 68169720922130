import React, { useContext, useEffect } from "react";

import AddressInput from "../../../components/AddressInput";
import { CartContext, LocationsContext } from "../../../context";
import _ from "lodash";
import { useTrans } from "../../../hooks";
import produce from "immer";
import NewAutoAddressInput from "../../../components/AddressInput/newAutocomplete";

const CheckoutAddressPickup = () => {
  const cartContext = useContext(CartContext);
  const locationsContext = useContext(LocationsContext);
  const transMsg = useTrans();

  useEffect(() => {
    if (_.isEmpty(locationsContext)) return;
    if (_.isEmpty(locationsContext.selectedLocation)) return;

    const populateAddress = {
      countryCode: _.upperCase(
        locationsContext.selectedLocation?.address?.countryCode || "CA",
      ),
      country: locationsContext.selectedLocation.address.country,
      city: locationsContext.selectedLocation.address.city,
      province: locationsContext.selectedLocation.address.province,
    };
    if (_.isEmpty(cartContext.billingAddress))
      cartContext.setBillingAddress({
        ...cartContext.billingAddress,
        ...populateAddress,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsContext]);
  return (
    <>
      <div>
        <h2> {transMsg("billingAddress")} </h2>
        <AddressInput
          uniqueKey="BillingAddressPickup"
          address={cartContext.billingAddress}
          setAddress={cartContext.setBillingAddress}
          fieldAttributes={{
            phone: {
              display: false,
              required: false,
              defaultValue: cartContext.cartPhone,
            },
            address_1: { display: false, require: false },
            address_2: { display: false, require: false },
            city: { display: false, require: false },
            province: { display: false, require: false },
            country: { display: false, require: false },
            postalCode: { display: false, require: false },
          }}
        />
        <NewAutoAddressInput
          uniqueKey="BillingAddressPickup"
          address={cartContext.billingAddress}
          onDone={(address) => {
            cartContext.setBillingAddress(
              produce(cartContext.billingAddress, (draft) => {
                _.map(address, (v, k) => {
                  draft[k] = v;
                });
              }),
            );
          }}
        />
      </div>
    </>
  );
};

export default CheckoutAddressPickup;
