import { useContext, useEffect } from "react";

import _ from "lodash";
import { CartContext } from "../../../../context";

const useSyncCartWhenMethodChange = ({ setOrderTypeChange }) => {
  const cartContext = useContext(CartContext);

  // update cart
  useEffect(() => {
    if (_.isEmpty(cartContext.cartToken)) return;
    if (_.isEmpty(cartContext.orderType)) return;

    setOrderTypeChange(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartContext.orderType]);
};

export default useSyncCartWhenMethodChange;
