import React, { useContext } from "react";
import { CartContext } from "../../context";
import { Form } from "react-bootstrap";
import { useTrans } from "../../hooks";

const UpdateCheckoutEmail = () => {
  const { cartEmail, setCartEmail } = useContext(CartContext);
  const transMsg = useTrans();

  return (
    <>
      <div className="form-label-group">
        <Form.Control
          type="email"
          id="inputEmail"
          placeholder={transMsg("emailAddress")}
          name="email"
          required
          value={cartEmail || ""}
          onChange={({ target: { value } }) => {
            setCartEmail(value);
          }}
        />
        <Form.Label htmlFor="inputEmail">
          {transMsg("email")} <span className="text-danger">*</span>
        </Form.Label>
      </div>
    </>
  );
};

export default UpdateCheckoutEmail;
