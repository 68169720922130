import React, { useContext } from "react";
import CheckoutAddressPickup from "./pickup";
import CheckoutAddressDelivery from "./delivery";
// import CheckoutAddressSigninPickup from "./signInPickup";
// import CheckoutAddressSigninDelivery from "./signInDelivery";

import { CartContext } from "../../../context";

const CheckoutAddress = () => {
  const cartContext = useContext(CartContext);
  // const { isAuthenticated } = useContext(UserAuthContext);

  return (
    <>
      {/* {isAuthenticated ? (
        <>
          {cartContext.orderType === "pickup" && (
            <CheckoutAddressSigninPickup />
          )}
          {cartContext.orderType === "delivery" && (
            <CheckoutAddressSigninDelivery />
          )}
        </>
      ) : (
       
      )} */}

      <>
        {cartContext.orderType === "pickup" && <CheckoutAddressPickup />}
        {cartContext.orderType === "delivery" && <CheckoutAddressDelivery />}
      </>
    </>
  );
};

export default CheckoutAddress;
